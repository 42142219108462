import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { convertPxToRem } from "../../../utils";
import IconLoader from "../../IconLoader";
import "./animations.css";
import { trackUserInteraction } from "../../../services/analytics-adapter";

interface HealthPlansAnimatedProps {
  onClick: Function;
  shouldAnimate?: boolean;
}

export default function HealthPlansAnimated({
  onClick,
  shouldAnimate = true,
}: HealthPlansAnimatedProps) {
  const { t } = useTranslation();

  function onNextButtonClick() {
    trackUserInteraction({
      linkText: "Health plans CVP | CVPFlow",
      linkIntent: "navigational",
      linkScope: "button",
    });
    onClick();
  }

  return (
    <Stack justifyContent="space-between" sx={{ height: "100%" }}>
      <Stack
        alignItems="center"
        bgcolor="accent2.main"
        sx={{
          clipPath: "ellipse(200% 57% at 15% 42%)",
          height: "60%",
          maxHeight: "400px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <IconLoader
          icon="Pattern4"
          color="info"
          sx={{
            fontSize: convertPxToRem(333),
            position: "relative",
            bottom: convertPxToRem(-100),
            left: (theme) => theme.spacing(1),
          }}
        />
        <Stack
          sx={{
            position: "absolute",
            top: (theme) => theme.spacing(14),
            alignSelf: "center",
            zIndex: 2,
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <IconLoader
            icon="SunIcon"
            color="secondary"
            sx={{
              fontSize: convertPxToRem(260),
              ...(shouldAnimate === true && {
                animation:
                  "slideUpAndFadeIn 0.5s, rotating 50s linear infinite",
              }),
            }}
          />
          <Box
            sx={{
              position: "absolute",
              alignSelf: "center",
              width: "50%",
              textAlign: "center",
              ...(shouldAnimate === true && {
                animation: "slideUpAndFadeIn 0.5s",
              }),
            }}
          >
            <Typography fontWeight={600} variant="h3">
              {t("CVPFlow.screens.HealthPlans.iconText")}
            </Typography>
          </Box>
        </Stack>
      </Stack>

      <Stack p={2} spacing={4}>
        <Typography variant="h1" textAlign="center">
          {t("CVPFlow.screens.HealthPlans.title")}
        </Typography>
        <Button onClick={onNextButtonClick}>{t("common.nextButton")}</Button>
      </Stack>
    </Stack>
  );
}
