import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import MaxWidthContainer from "../MaxWidthContainer";
import HealthCheckAnimated from "./HealthCheckAnimated";
import HealthPlansAnimated from "./HealthPlansAnimated";
import HealthScoreAnimated from "./HealthScoreAnimated";
import MedicalAssistanceChatAnimated from "./MedicalAssistanceChatAnimated";
import Welcome from "./Welcome";

export default function CVPFlowExamples() {
  const [startHealthPlansAnimation, setStartHealthPlansAnimation] =
    useState(false);
  const [
    startMedicalAssistanceChatAnimation,
    setStartMedicalAssistanceChatAnimation,
  ] = useState(false);
  const [startHealthScoreAnimation, setStartHealthScoreAnimation] =
    useState(false);
  const [startHealthCheckAnimation, setStartHealthCheckAnimation] =
    useState(false);

  return (
    <MaxWidthContainer>
      <Typography variant="h3">HealthCheckAnimated</Typography>
      <Stack direction="row" spacing={1} my={2}>
        <Button
          color="primary"
          onClick={() => setStartHealthCheckAnimation(true)}
        >
          Start animation
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setStartHealthCheckAnimation(false)}
        >
          Stop animation
        </Button>
      </Stack>
      <Box bgcolor="background.default" height="100vh">
        <HealthCheckAnimated
          shouldAnimate={startHealthCheckAnimation}
          onClick={() => {}}
        />
      </Box>

      <Typography variant="h3">HealthScoreAnimated</Typography>
      <Stack direction="row" spacing={1} my={2}>
        <Button
          color="primary"
          onClick={() => setStartHealthScoreAnimation(true)}
        >
          Start animation
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setStartHealthScoreAnimation(false)}
        >
          Stop animation
        </Button>
      </Stack>
      <Box bgcolor="background.default" height="100vh">
        <HealthScoreAnimated
          shouldAnimate={startHealthScoreAnimation}
          onClick={() => {}}
        />
      </Box>

      <Typography variant="h3">MedicalAssistanceChatAnimated</Typography>
      <Stack direction="row" spacing={1} my={2}>
        <Button
          color="primary"
          onClick={() => setStartMedicalAssistanceChatAnimation(true)}
        >
          Start animation
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setStartMedicalAssistanceChatAnimation(false)}
        >
          Stop animation
        </Button>
      </Stack>
      <Box bgcolor="background.default" height="100vh">
        <MedicalAssistanceChatAnimated
          shouldAnimate={startMedicalAssistanceChatAnimation}
          onClick={() => {}}
        />
      </Box>

      <Typography variant="h3">HealthPlansAnimated</Typography>
      <Stack direction="row" spacing={1} my={2}>
        <Button
          color="primary"
          onClick={() => setStartHealthPlansAnimation(true)}
        >
          Start animation
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setStartHealthPlansAnimation(false)}
        >
          Stop animation
        </Button>
      </Stack>
      <Box bgcolor="background.default" height="100vh">
        <HealthPlansAnimated
          shouldAnimate={startHealthPlansAnimation}
          onClick={() => {}}
        />
      </Box>

      <Box bgcolor="background.default" height="100vh">
        <Welcome onClick={() => {}} />
      </Box>
    </MaxWidthContainer>
  );
}
