import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "../../../services/i18n";
import { convertPxToRem } from "../../../utils";
import IconLoader from "../../IconLoader";
import "./animations.css";
import { trackUserInteraction } from "../../../services/analytics-adapter";

interface MedicalAssistanceChatProps {
  onClick: Function;
  shouldAnimate?: boolean;
}

export default function MedicalAssistanceChat({
  onClick,
  shouldAnimate = true,
}: MedicalAssistanceChatProps) {
  const { t } = useTranslation();

  function onNextButtonClick() {
    trackUserInteraction({
      linkText: "Medical assistance CVP | CVPFlow",
      linkIntent: "navigational",
      linkScope: "button",
    });
    onClick();
  }

  return (
    <Stack justifyContent="space-between" sx={{ height: "100%" }}>
      <Stack
        alignItems="center"
        bgcolor="error.main"
        sx={{
          clipPath: "ellipse(200% 57% at 15% 42%)",
          height: "60%",
          maxHeight: "400px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <IconLoader
          icon="Pattern1"
          color="info"
          sx={{
            fontSize: convertPxToRem(333),
            position: "relative",
            bottom: convertPxToRem(-220),
            right: convertPxToRem(-30),
            transform: "rotate(180deg) scaleX(-1)",
          }}
        />
        <Stack
          sx={{
            position: "absolute",
            right: convertPxToRem(50),
            bottom: convertPxToRem(140),
            height: "100%",
            top: "20%",
            left: "80%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box
            component="img"
            src={`/components/CVPFlow/${i18n.resolvedLanguage}/MedicalAssistanceChatAnimated/nurseConsult.png`}
            sx={{
              width: "310px",
              position: "absolute",
              right: 0,
              bottom: "0px",
              opacity: 0,
              zIndex: 2,
              ...(shouldAnimate === true && {
                animation: "moveAndFade 1s ease-in-out forwards",
              }),
            }}
            alt={t(
              "CVPFlow.screens.MedicalAssistanceChat.animationAlternateText"
            )}
          />
          <Box
            component="img"
            src={`/components/CVPFlow/${i18n.resolvedLanguage}/MedicalAssistanceChatAnimated/doctorConsult.png`}
            sx={{
              width: "300px",
              position: "absolute",
              right: convertPxToRem(-35),
              bottom: convertPxToRem(-87),
              opacity: 0,
              zIndex: 1,
              ...(shouldAnimate === true && {
                animation: "moveAndFade 1s ease-in-out forwards",
                animationDelay: ".3s",
              }),
            }}
            alt={t(
              "CVPFlow.screens.MedicalAssistanceChat.animationAlternateText"
            )}
          />
        </Stack>
      </Stack>
      <Stack p={2} spacing={4}>
        <Typography variant="h1" textAlign="center">
          {t("CVPFlow.screens.MedicalAssistanceChat.title")}
        </Typography>
        <Button onClick={onNextButtonClick}>{t("common.nextButton")}</Button>
      </Stack>
    </Stack>
  );
}
