import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../services/i18n";
import { convertPxToRem } from "../../../utils";
import IconLoader from "../../IconLoader";
import "./animations.css";
import { trackUserInteraction } from "../../../services/analytics-adapter";
interface HealthScoreAnimatedProps {
  onClick: Function;
  shouldAnimate?: boolean;
}

const MAX_HEALTH_SCORE = 88;

export default function HealthScoreAnimated({
  onClick,
  shouldAnimate,
}: HealthScoreAnimatedProps) {
  const { t } = useTranslation();
  const [healthScoreCounter, setHealthScoreCounter] = useState(15);

  function onNextButtonClick() {
    trackUserInteraction({
      linkText: "Health score CVP | CVPFlow",
      linkIntent: "navigational",
      linkScope: "button",
    });
    onClick();
  }

  useEffect(() => {
    let healthScoreSetTimer: any = null;

    if (healthScoreCounter < MAX_HEALTH_SCORE) {
      healthScoreSetTimer = setTimeout(() => {
        setHealthScoreCounter((prevCount: number) => prevCount + 1);
      }, 20);
    }

    if (!shouldAnimate) {
      return setHealthScoreCounter(0);
    }

    return () => {
      if (healthScoreSetTimer) {
        clearTimeout(healthScoreSetTimer);
      }
    };
  }, [healthScoreCounter, shouldAnimate]);

  return (
    <Stack justifyContent="space-between" sx={{ height: "100%" }}>
      <Stack
        alignItems="center"
        bgcolor="secondary.main"
        sx={{
          clipPath: "ellipse(200% 57% at 15% 42%)",
          height: "60%",
          maxHeight: "400px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <IconLoader
          icon="Pattern5"
          color="info"
          sx={{
            fontSize: convertPxToRem(400),
            position: "relative",
            top: (theme) => theme.spacing(2.5),
            left: (theme) => theme.spacing(14),
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: convertPxToRem(24),
            left: 0,
            width: "326px",
            opacity: 0,
            zIndex: 2,
            ...(shouldAnimate === true && {
              animation: "scaleUp 1.05s forwards",
            }),
          }}
        >
          <Typography
            variant="body2"
            sx={{
              position: "absolute",
              top: convertPxToRem(63),
              left: convertPxToRem(48),
              ...(shouldAnimate === true && {
                animation: "animateCounter 1.25s forwards",
              }),
            }}
          >
            {healthScoreCounter}
          </Typography>

          <Box
            component="img"
            src={`/components/CVPFlow/${i18n.resolvedLanguage}/HealthScoreAnimated/BackgroundScreen.png`}
            sx={{
              width: "100%",
            }}
            alt={t("CVPFlow.screens.HealthScore.animationAlternateText")}
          />

          <Box
            sx={{
              position: "absolute",
              right: convertPxToRem(152),
              top: convertPxToRem(86),
              opacity: 0,
              zIndex: 3,
              ...(shouldAnimate === true && {
                animation: "slideAndFadeInFromLeft 2.25s forwards",
              }),
            }}
          >
            <IconLoader
              icon="HealthPin"
              sx={{
                opacity: 0,
                fontSize: convertPxToRem(28),
                ...(shouldAnimate === true && {
                  animation: "colorFader 1.65s forwards",
                  animationDelay: ".10s",
                }),
              }}
            />
          </Box>
        </Box>
      </Stack>
      <Stack p={2} spacing={4}>
        <Typography variant="h1" textAlign="center">
          {t("CVPFlow.screens.HealthScore.title")}
        </Typography>
        <Button onClick={onNextButtonClick}>{t("common.nextButton")}</Button>
      </Stack>
    </Stack>
  );
}
