import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { convertPxToRem } from "../../../utils";
import IconLoader from "../../IconLoader";
import { trackUserInteraction } from "../../../services/analytics-adapter";

interface Props {
  onClick: Function;
}

export default function Welcome({ onClick }: Props) {
  const { t } = useTranslation();

  function onNextButtonClick() {
    trackUserInteraction({
      linkText: "Welcome CVP | CVPFlow",
      linkIntent: "navigational",
      linkScope: "button",
    });
    onClick();
  }

  return (
    <Stack justifyContent="space-between" sx={{ height: "100%" }}>
      <Stack
        alignItems="center"
        justifyContent="end"
        sx={{
          background: (theme) => theme.palette.primary[700],
          clipPath: "ellipse(200% 57% at 15% 42%)",
          height: "60%",
          maxHeight: "400px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <IconLoader
          icon="FaceIcon"
          color="primary"
          sx={{ fontSize: convertPxToRem(260) }}
        />
      </Stack>
      <Stack p={2} spacing={4}>
        <Typography variant="h1" textAlign="center">
          {t("CVPFlow.screens.Welcome.title")}
        </Typography>
        <Button onClick={onNextButtonClick}>
          {t("common.continueButton")}
        </Button>
      </Stack>
    </Stack>
  );
}
